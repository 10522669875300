export enum CowMilkProfilerActionTypes {
    GET_COMPOUNDS_REQUEST = '[COW MILK PROFILER][COMPOUNDS] Get Compounds Request',
    GET_COMPOUNDS_SUCCESS = '[COW MILK PROFILER][COMPOUNDS] Get Compounds Success',
    GET_COMPOUNDS_FAILURE = '[COW MILK PROFILER][COMPOUNDS] Get Compounds Failure',
    GET_INSIGHTS_REQUEST = '[COW MILK PROFILER][INSIGHTS] Get Insights Request',
    GET_INSIGHTS_SUCCESS = '[COW MILK PROFILER][INSIGHTS] Get Insights Success',
    GET_INSIGHTS_FAILURE = '[COW MILK PROFILER][INSIGHTS] Get Insights Failure',
    GET_COW_MILK_INSIGHTS_REQUEST = '[COW MILK PROFILER][INSIGHTS] Get Cow Milk Insights Request',
    GET_COW_MILK_INSIGHTS_SUCCESS = '[COW MILK PROFILER][INSIGHTS] Get Cow Milk Insights Success',
    GET_COW_MILK_INSIGHTS_FAILURE = '[COW MILK PROFILER][INSIGHTS] Get Cow Milk Insights Failure',
    GET_TARGET_INSIGHTS_REQUEST = '[COW MILK PROFILER][INSIGHTS] Get Target Insights Request',
    GET_TARGET_INSIGHTS_SUCCESS = '[COW MILK PROFILER][INSIGHTS] Get Target Insights Success',
    GET_TARGET_INSIGHTS_FAILURE = '[COW MILK PROFILER][INSIGHTS] Get Target Insights Failure',
    DOWNLOAD_INSIGHTS_REQUEST = '[COW MILK PROFILER][DOWNLOAD] Download Insights Request',
    DOWNLOAD_INSIGHTS_SUCCESS = '[COW MILK PROFILER][DOWNLOAD] Download Insights Success',
    DOWNLOAD_INSIGHTS_FAILURE = '[COW MILK PROFILER][DOWNLOAD] Download Insights Failure',
    GET_SEARCH_SUGGESTIONS_REQUEST = '[COW MILK PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Request',
    GET_SEARCH_SUGGESTIONS_SUCCESS = '[COW MILK PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Success',
    GET_SEARCH_SUGGESTIONS_FAILURE = '[COW MILK PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Failure',
    CLEAR_SEARCH_SUGGESTIONS = '[COW MILK PROFILER][SEARCH SUGGESTIONS] Clear Search Suggestions',
    RESET_DISCOVERY = '[COW MILK PROFILER] Reset Discovery',
    CLEAR_NEXT_ERROR = '[COW MILK PROFILER] Clear Next Error',
}
