/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { DairyProfilerState } from './dairy-profiler-state.interface';
import { getDataState } from '../index';
import * as fromDairyProfiler from './dairy-profiler.reducer';
import Insight from '../ingredient-profiler/interfaces/insight.interface';
import EnhancedInsights from '../ingredient-profiler/interfaces/enhanced-insights';

export const getDairyProfilerState = createSelector(
    getDataState,
    (state: DataState) => state.dairyProfiler,
);

export const getErrors = createSelector(getDairyProfilerState, fromDairyProfiler.getErrors);
export const getCompoundsTotal = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getCompoundsTotal,
);
export const getCompoundsDisplaying = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getCompoundsDisplaying,
);
export const getCompoundsLoading = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getCompoundsLoading,
);
export const getCompoundsLoaded = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getCompoundsLoaded,
);
export const getParentCompoundId = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getParentCompoundId,
);
export const getInsights = createSelector(getDairyProfilerState, fromDairyProfiler.getInsights);
export const getInsightsTotal = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getInsightsTotal,
);
export const getInsightsDisplaying = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getInsightsDisplaying,
);
export const getInsightsPageIndex = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getInsightsPageIndex,
);
export const getInsightsLoading = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getInsightsLoading,
);
export const getInsightsLoaded = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getInsightsLoaded,
);
export const getDairyProductInsightsTotal = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getDairyProductInsightsTotal,
);
export const getDairyProductInsightsLoading = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getDairyProductInsightsLoading,
);
export const getDairyProductInsightsLoaded = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getDairyProductInsightsLoaded,
);
export const getTargetInsights = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getTargetInsights,
);
export const getTargetInsightsTotal = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getTargetInsightsTotal,
);
export const getTargetInsightsLoading = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getTargetInsightsLoading,
);
export const getTargetInsightsLoaded = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getTargetInsightsLoaded,
);
export const getOldestOccurrence = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getOldestOccurrence,
);
export const getNewestOccurrence = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getNewestOccurrence,
);
export const getPreferences = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getPreferences,
);
export const getMinMoleculeWeight = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getMinMoleculeWeight,
);
export const getMaxMoleculeWeight = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getMaxMoleculeWeight,
);
export const getBlob = createSelector(getDairyProfilerState, fromDairyProfiler.getBlob);
export const getSearchSuggestions = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getSearchSuggestions,
);
export const getSearchSuggestionsLoading = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getSearchSuggestionsLoading,
);
export const getSearchSuggestionsLoaded = createSelector(
    getDairyProfilerState,
    fromDairyProfiler.getSearchSuggestionsLoaded,
);

export const getEnhancedInsights: (state: DairyProfilerState) => EnhancedInsights = (
    state: DairyProfilerState,
) => {
    const parentCompoundId: string = getParentCompoundId(state);
    const insights: Insight[] = getInsights(state);
    const loading: boolean = getInsightsLoading(state);
    const loaded: boolean = getInsightsLoaded(state);
    const total: number = getInsightsTotal(state);
    const displaying: number = getInsightsDisplaying(state);

    return {
        parentCompoundId,
        insights,
        loading,
        loaded,
        total,
        displaying,
    };
};
