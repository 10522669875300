import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { expand, switchMap, takeWhile } from 'rxjs/operators';

/** Interfaces */
import PollingResult from '@leap-common/interfaces/polling-result.interface';

@Injectable()
export class PollingService {
    constructor() {}

    /**
     * Repeatedly calls the poll function at a fixed interval until one of the completed statuses
     * is returned. Returns an observable that emits the result of the poll function after each
     * invocation.
     *
     * An example of what the observable might emit is as follows:
     * { status: 'pending', results: null }
     * (time interval)
     * { status: 'pending', results: null }
     * (time interval)
     * { status: 'complete', results: [1, 2, 3] }
     *
     * @param poll The function to poll, usually a service method.
     * @param completedStatuses The statuses that indicate that the poll is complete.
     * @param interval The interval in milliseconds between each poll.
     * @returns An observable that emits the result of each poll.
     */
    pollUntilComplete<T extends PollingResult>(
        poll: () => Observable<T>,
        completedStatuses: string[],
        interval: number,
    ): Observable<T> {
        return poll().pipe(
            expand((result: T) =>
                completedStatuses.includes(result.status)
                    ? of(result)
                    : timer(interval).pipe(switchMap(() => poll())),
            ),
            takeWhile((result: T) => !completedStatuses.includes(result.status), true),
        );
    }
}
