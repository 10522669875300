/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, mergeMap, map, catchError, exhaustMap, filter } from 'rxjs/operators';

/** custom imports */
import { BookmarksService } from './services/bookmarks.service';
import * as bookmarksActions from './bookmarks.actions';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import PollingResult from '@leap-common/interfaces/polling-result.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import PaginatedBookmarks from './interfaces/paginated-bookmarks.interface';
import BookmarkType from './enums/bookmark.enum';
import Project from '../projects/interfaces/project.interface';
import BookmarkOrigin from './interfaces/origin.interface';
import BookmarkConfiguration from './interfaces/configuration.interface';
import Alert from '../../ui/alerts/interfaces/alert.interface';
import BookmarkIds from './interfaces/bookmark-ids.interface';
import Bookmark from './interfaces/bookmark.interface';
import FileStatus from './enums/file-status.enum';

@Injectable()
export class BookmarksEffects {
    constructor(private actions$: Actions, private bookmarksService: BookmarksService) {}

    getBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.getBookmarksRequest),
            mergeMap(
                ({
                    projectId,
                    bookmarkType,
                    filters,
                    preferences,
                    studyTypesOrder,
                    pageIndex,
                    pageSize,
                    sortingOptions,
                }: {
                    projectId: string;
                    bookmarkType: BookmarkType;
                    filters: ExecutionFilters;
                    preferences: UserPreferences;
                    studyTypesOrder: string[];
                    pageIndex: number;
                    pageSize: number;
                    sortingOptions: SortingOptions;
                }) =>
                    this.bookmarksService
                        .getBookmarks(
                            projectId,
                            bookmarkType,
                            filters,
                            preferences,
                            studyTypesOrder,
                            pageIndex,
                            pageSize,
                            sortingOptions,
                        )
                        .pipe(
                            map((paginatedBookmarks: PaginatedBookmarks) =>
                                bookmarksActions.getBookmarksSuccess({
                                    paginatedBookmarks,
                                    bookmarkType,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    bookmarksActions.getBookmarksFailure({
                                        errorResponse,
                                        bookmarkType,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    createEntityBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.createEntityBookmarksRequest),
            switchMap(
                ({
                    projects,
                    origin,
                    configuration,
                    notes,
                }: {
                    projects: Project[];
                    origin: BookmarkOrigin;
                    configuration: BookmarkConfiguration;
                    notes: string;
                }) =>
                    this.bookmarksService
                        .createEntityBookmarks(projects, origin, configuration, notes)
                        .pipe(
                            map(({ alert }: { alert: Alert }) =>
                                bookmarksActions.createEntityBookmarksSuccess({ alert }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    bookmarksActions.createEntityBookmarksFailure({
                                        errorResponse,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    createAssociationOpenBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.createAssociationOpenBookmarksRequest),
            switchMap(
                ({
                    projects,
                    configuration,
                    notes,
                }: {
                    projects: Project[];
                    configuration: BookmarkConfiguration;
                    notes: string;
                }) =>
                    this.bookmarksService
                        .createAssociationOpenBookmarks(projects, configuration, notes)
                        .pipe(
                            map(({ alert }: { alert: Alert }) =>
                                bookmarksActions.createAssociationOpenBookmarksSuccess({ alert }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    bookmarksActions.createAssociationOpenBookmarksFailure({
                                        errorResponse,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    createAssociationClosedBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.createAssociationClosedBookmarksRequest),
            switchMap(
                ({
                    projects,
                    configuration,
                    notes,
                }: {
                    projects: Project[];
                    configuration: BookmarkConfiguration;
                    notes: string;
                }) =>
                    this.bookmarksService
                        .createAssociationClosedBookmarks(projects, configuration, notes)
                        .pipe(
                            map(({ alert }: { alert: Alert }) =>
                                bookmarksActions.createAssociationClosedBookmarksSuccess({
                                    alert,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    bookmarksActions.createAssociationClosedBookmarksFailure({
                                        errorResponse,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    createArticleBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.createArticleBookmarksRequest),
            switchMap(
                ({
                    projects,
                    origin,
                    configurations,
                    notes,
                }: {
                    projects: Project[];
                    origin: BookmarkOrigin;
                    configurations: BookmarkConfiguration[];
                    notes: string;
                }) =>
                    this.bookmarksService
                        .createArticleBookmarks(projects, origin, configurations, notes)
                        .pipe(
                            map(({ alert }: { alert: Alert }) =>
                                bookmarksActions.createArticleBookmarksSuccess({
                                    alert,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    bookmarksActions.createArticleBookmarksFailure({
                                        errorResponse,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    uploadArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.uploadArticleRequest),
            switchMap(({ projectId, doi, file }: { projectId: string; doi: string; file: File }) =>
                doi
                    ? this.bookmarksService.uploadArticleDOI(projectId, doi).pipe(
                          map(() => bookmarksActions.uploadArticleSuccess()),
                          catchError((errorResponse: HttpErrorResponse) =>
                              of(
                                  bookmarksActions.uploadArticleFailure({
                                      errorResponse,
                                  }),
                              ),
                          ),
                      )
                    : file
                    ? this.bookmarksService.uploadArticleFile(projectId, file).pipe(
                          exhaustMap((taskId: string) =>
                              this.bookmarksService.pollUploadArticleFileStatusUntilComplete(
                                  projectId,
                                  taskId,
                              ),
                          ),
                          filter(
                              ({ status }: PollingResult<FileStatus>) =>
                                  status === FileStatus.success || status === FileStatus.failure,
                          ),
                          map(({ status }: PollingResult<FileStatus>) =>
                              status === FileStatus.success
                                  ? bookmarksActions.uploadArticleSuccess()
                                  : bookmarksActions.uploadArticleFailure({
                                        errorResponse: { error: null } as HttpErrorResponse,
                                        isFileError: true,
                                    }),
                          ),
                          catchError((errorResponse: HttpErrorResponse) =>
                              of(
                                  bookmarksActions.uploadArticleFailure({
                                      errorResponse,
                                      isFileError: true,
                                  }),
                              ),
                          ),
                      )
                    : null,
            ),
            catchError((errorResponse: HttpErrorResponse) =>
                of(
                    bookmarksActions.uploadArticleFailure({
                        errorResponse,
                    }),
                ),
            ),
        ),
    );

    updateNotes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.updateNotesRequest),
            switchMap(({ bookmarkIds, notes }: { bookmarkIds: BookmarkIds; notes: string }) =>
                this.bookmarksService.updateNotes(bookmarkIds, notes).pipe(
                    map(() => bookmarksActions.updateNotesSuccess()),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            bookmarksActions.updateNotesFailure({
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    downloadArticleBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.downloadArticleBookmarksRequest),
            switchMap(({ projectId, filters }: { projectId: string; filters: ExecutionFilters }) =>
                this.bookmarksService.downloadArticleBookmarks(projectId, filters).pipe(
                    map((blob: Blob) => bookmarksActions.downloadArticleBookmarksSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(bookmarksActions.downloadArticleBookmarksFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.deleteBookmarkRequest),
            mergeMap(
                ({
                    projectId,
                    bookmarkId,
                    bookmarks,
                    bookmarkType,
                }: {
                    projectId: string;
                    bookmarkId: string;
                    bookmarks: Bookmark[];
                    bookmarkType: BookmarkType;
                }) =>
                    this.bookmarksService.deleteBookmark(projectId, bookmarkId, bookmarkType).pipe(
                        map(() =>
                            bookmarksActions.deleteBookmarkSuccess({
                                projectId,
                                bookmarkId,
                                bookmarks,
                                bookmarkType,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                bookmarksActions.deleteBookmarkFailure({
                                    projectId,
                                    bookmarkId,
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
