/** third-party imports */
import { Injectable } from '@angular/core';

/** Constants */
import { EFFECTS } from '@apps/leap/src/app/shared/modules/profiler-report/constants/report';

/** Helpers */
import { mapDecimalToPercentage } from '@leap-common/utilities/helpers';

/** Services - Parsers */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { ReportParser } from '@leap-store/core/src/lib/data/report/parsers/report.parser';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { EffectsParser } from '@apps/leap/src/app/shared/parsers/effects.parser';
import { MetadataParser } from '../../metadata/parsers/metadata.parser';

/** Interfaces - Types - Enums */
import StatisticsItem from '../../report/interfaces/statistics-item.interface';
import Overview from '../interfaces/overview.interface';
import OverviewRestApi from '../rest-api-interfaces/overview.rest.interface';
import EffectStatisticsItemRestApi from '../rest-api-interfaces/effect-statistics-item.rest.interface';
import TermSentencesRestApi from '../rest-api-interfaces/term-sentences.rest.interface';
import Identifier from '../../metadata/interfaces/identifier.interface';
import IdentifierRestApi from '../../metadata/rest-api-types/identifier.rest.type';
import Effect from '../../ingredient-profiler/enums/effect.enum';
import HealthLabel from '../interfaces/health-label.interface';
import HealthLabelRestApi from '../rest-api-interfaces/health-label.rest.interface';
import Target from '../interfaces/target.interface';
import TargetRestApi from '../rest-api-interfaces/target.rest.interface';
import StudyTypes from '../interfaces/study-types.interface';
import StudyTypesRestApi from '../rest-api-interfaces/study-types.rest.interface';
import StudyType from '../interfaces/study-type.interface';
import StudyTypeRestApi from '../rest-api-interfaces/study-type.rest.interface';
import StudyTypeStatisticsItemRestApi from '../rest-api-interfaces/study-type-statistics-item.rest.interface';
import JournalsPerStudyType from '../interfaces/journals-per-study-type.interface';
import SummaryRestApi from '../rest-api-interfaces/summary.rest.interface';
import Summary from '../../report/interfaces/summary.interface';
import TermsSentences from '../interfaces/terms-sentences.interface';
import TermSentences from '../interfaces/term-sentences.interface';
import Sentence from '../interfaces/sentence.interface';
import SentenceRestApi from '../rest-api-interfaces/sentence.rest.interface';

@Injectable()
export class CompoundReportParser {
    constructor(
        private arrayHandlerService: ArrayHandlerService,
        private reportParser: ReportParser,
        private insightParser: InsightParser,
        private effectsParser: EffectsParser,
        private metadataParser: MetadataParser,
    ) {}

    parseOverview(overview: OverviewRestApi): Overview {
        return {
            name: overview.name,
            synonyms: overview.synonyms || [],
            definitions: overview.definitions || {},
            healthLabels: this.insightParser.parseHealthLabels(overview.healthAreas),
            molecules: this.insightParser.parseMolecules(overview.moleculeClassification),
            labs: this.insightParser.parseLabs(overview.ucdDmdLab),
            moleculeWeight: overview.molecularWeight,
            identifiers: this.parseIdentifiers(overview.identifiers),
            effects: this.parseEffectStatistics(overview.statistics),
            cowMilkConcentration: overview.cowMilkConcentration,
            prevalence: this.metadataParser.parsePrevalence(overview.prevalence),
            profileCategory: this.insightParser.parseProfileCategories(
                overview.uniquenessProfile,
            )?.[0],
        };
    }

    parseIdentifiers(identifiers: IdentifierRestApi[]): Identifier[] {
        return identifiers
            ? identifiers.map((identifier: IdentifierRestApi) =>
                  this.metadataParser.parseIdentifier(identifier),
              )
            : [];
    }

    parseEffectStatistics(effectStatistics: EffectStatisticsItemRestApi[]): StatisticsItem[] {
        return (
            effectStatistics
                ?.filter(
                    ({ effect }: EffectStatisticsItemRestApi) =>
                        this.effectsParser.parseEffect(effect) !== Effect.unclassified,
                )
                ?.map(({ effect, count, percentage }: EffectStatisticsItemRestApi) => ({
                    name: this.effectsParser.parseEffect(effect),
                    value: count,
                    percentage: percentage ? mapDecimalToPercentage(percentage, 2) : 0,
                }))
                .sort(
                    this.arrayHandlerService.getSortOrder(
                        EFFECTS,
                        ({ name }: StatisticsItem) => name as Effect,
                    ),
                ) || []
        );
    }

    parseTotal(overview: OverviewRestApi): number {
        return overview?.total || 0;
    }

    parseTermsSentences(
        targetsSentences: TermSentencesRestApi[],
        effect: Effect,
        healthLabel?: string,
    ): TermsSentences {
        return {
            effect,
            healthLabel,
            sentences:
                targetsSentences?.map((termSentences: TermSentencesRestApi) =>
                    this.parseTermSentences(termSentences),
                ) || [],
        };
    }

    parseTermSentences(termSentences: TermSentencesRestApi): TermSentences {
        return {
            term: this.parseSentenceTerm(termSentences),
            sentences: this.parseSentences(termSentences.topSentences),
        };
    }

    parseSentenceTerm(termSentences: TermSentencesRestApi): Target {
        return {
            id: termSentences.associatedTermUid,
            name: termSentences.associatedTermName,
            categories: this.insightParser.parseCategories(termSentences.associatedTermCategories),
        };
    }

    parseSentences(sentences: SentenceRestApi[]): Sentence[] {
        return sentences?.map((sentence: SentenceRestApi) => this.parseSentence(sentence)) || [];
    }

    parseSentence(sentence: SentenceRestApi): Sentence {
        return sentence
            ? {
                  reference: this.reportParser.parseReference(sentence.articleInfo),
                  text: sentence.sentenceDetails?.sentenceText,
                  sourceHighlights: sentence.sentenceDetails?.sourceSpans
                      ? [sentence.sentenceDetails.sourceSpans]
                      : [],
                  targetHighlights: sentence.sentenceDetails?.targetSpans
                      ? [sentence.sentenceDetails.targetSpans]
                      : [],
              }
            : null;
    }

    parseHealthLabels(healthLabels: HealthLabelRestApi[]): HealthLabel[] {
        return (
            healthLabels?.map((healthLabel: HealthLabelRestApi) =>
                this.parseHealthLabel(healthLabel),
            ) || []
        );
    }

    parseHealthLabel(healthLabel: HealthLabelRestApi): HealthLabel {
        return {
            name: healthLabel.healthArea,
            targetsCount: healthLabel.totalAssociations,
            filteredTargetsCount: healthLabel.noOfAssociations,
        };
    }

    parseTargets(targets: TargetRestApi[]): Target[] {
        return targets?.map((target: TargetRestApi) => this.parseTarget(target)) || [];
    }

    parseTarget(target: TargetRestApi): Target {
        return {
            id: target.targetUids,
            name: target.associatedMedicalTerm,
            rankingIndex: target.index,
            articlesCount: target.noOfArticles,
            studyTypeArticlesCount: target.typeOfStudyArticles,
            relationship: this.insightParser.parseRelationshipType(target.relationshipType),
            references: this.reportParser.parseReferences(target.references),
            coOccurrencesCount: target.noOfCooccurrences,
            categories: target.targetCategories,
        };
    }

    parseStudyTypes(studyTypes: StudyTypesRestApi): StudyTypes {
        return {
            statistics: this.parseStudyTypeStatistics(studyTypes.articlesPerTypeOfStudy),
            journalsStatistics: this.parseJournalsStatistics(studyTypes.journalsPerTypeOfStudy),
        };
    }

    parseStudyTypeStatistics(
        studyTypeStatistics: Record<string, StudyTypeStatisticsItemRestApi>,
    ): StatisticsItem[] {
        return studyTypeStatistics
            ? Object.entries(studyTypeStatistics).reduce(
                  (
                      accumulator: StatisticsItem[],
                      [studyType, statistics]: [string, StudyTypeStatisticsItemRestApi],
                  ) => {
                      accumulator.push({
                          name: studyType,
                          value: statistics.counts,
                          percentage: statistics.percentage
                              ? mapDecimalToPercentage(statistics.percentage, 2)
                              : 0,
                      });
                      return accumulator;
                  },
                  [],
              )
            : [];
    }

    parseJournalsStatistics(
        journalsStatistics: Record<string, Record<string, number>>,
    ): JournalsPerStudyType[] {
        return journalsStatistics
            ? Object.entries(journalsStatistics)?.reduce(
                  (
                      accumulator: JournalsPerStudyType[],
                      [studyType, counts]: [string, Record<string, number>],
                  ) => {
                      const journals: StatisticsItem[] = this.parseStudyTypeJournals(counts);

                      if (journals.length) {
                          accumulator.push({
                              studyType,
                              journals,
                          });
                      }

                      return accumulator;
                  },
                  [],
              )
            : [];
    }

    parseStudyTypeJournals(counts: Record<string, number>): StatisticsItem[] {
        return (
            Object.entries(counts)?.reduce(
                (accumulator: StatisticsItem[], [journal, count]: [string, number]) => {
                    if (count) {
                        accumulator.push({
                            name: journal,
                            value: count,
                        });
                    }

                    return accumulator;
                },
                [],
            ) || []
        ).sort(
            (itemA: StatisticsItem, itemB: StatisticsItem) =>
                itemB.value - itemA.value || itemA.name.localeCompare(itemB.name),
        );
    }

    parseStudyType(studyType: StudyTypeRestApi, name: string): StudyType {
        return {
            name,
            summary: this.parseSummary(studyType.summary),
            topTargets: this.parseTargets(studyType.topAssociations),
        };
    }

    // TODO: move this to a common parser
    parseSummary(summary: SummaryRestApi, name?: string, group?: string): Summary {
        return {
            name,
            group,
            text: summary.summary,
            references: this.reportParser.parseReferences(summary.references),
        };
    }
}
