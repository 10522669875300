/** third-party imports */
import { createAction, props } from '@ngrx/store';

/** custom imports */
import { HealthLabelReportActionTypes } from './health-label-report-action-types.enum';
import { HttpErrorResponse } from '@angular/common/http';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import Overview from './interfaces/overview.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import Compound from './interfaces/compound.interface';
import Target from './interfaces/target.interface';
import Effect from '../ingredient-profiler/enums/effect.enum';
import TermSentences from '../compound-report/interfaces/term-sentences.interface';
import ProfilerEntity from '../ingredient-profiler/enums/entity.enum';

export const getOverviewRequest = createAction(
    HealthLabelReportActionTypes.GET_OVERVIEW_REQUEST,
    props<{
        ingredientName: string;
        countBy: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const getOverviewSuccess = createAction(
    HealthLabelReportActionTypes.GET_OVERVIEW_SUCCESS,
    props<{ overview: Overview }>(),
);

export const getOverviewFailure = createAction(
    HealthLabelReportActionTypes.GET_OVERVIEW_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTopCompoundsRequest = createAction(
    HealthLabelReportActionTypes.GET_TOP_COMPOUNDS_REQUEST,
    props<{
        ingredientName?: string;
        healthLabel: string;
        effect: Effect;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const getTopCompoundsSuccess = createAction(
    HealthLabelReportActionTypes.GET_TOP_COMPOUNDS_SUCCESS,
    props<{ topCompounds: Compound[] }>(),
);

export const getTopCompoundsFailure = createAction(
    HealthLabelReportActionTypes.GET_TOP_COMPOUNDS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTopTargetsRequest = createAction(
    HealthLabelReportActionTypes.GET_TOP_TARGETS_REQUEST,
    props<{
        ingredientName?: string;
        healthLabel: string;
        effect: Effect;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const getTopTargetsSuccess = createAction(
    HealthLabelReportActionTypes.GET_TOP_TARGETS_SUCCESS,
    props<{ topTargets: Target[] }>(),
);

export const getTopTargetsFailure = createAction(
    HealthLabelReportActionTypes.GET_TOP_TARGETS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getSentencesRequest = createAction(
    HealthLabelReportActionTypes.GET_SENTENCES_REQUEST,
    props<{
        ingredientName: string;
        id: string;
        termType: ProfilerEntity;
        healthLabel: string;
        effect: Effect;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const getSentencesSuccess = createAction(
    HealthLabelReportActionTypes.GET_SENTENCES_SUCCESS,
    props<{ sentences: TermSentences[] }>(),
);

export const getSentencesFailure = createAction(
    HealthLabelReportActionTypes.GET_SENTENCES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadReportRequest = createAction(
    HealthLabelReportActionTypes.DOWNLOAD_REPORT_REQUEST,
    props<{
        ingredientName: string;
        healthLabel: string;
        effect: Effect;
        termId: string;
        termType: ProfilerEntity;
        countBy: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const downloadReportSuccess = createAction(
    HealthLabelReportActionTypes.DOWNLOAD_REPORT_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadReportFailure = createAction(
    HealthLabelReportActionTypes.DOWNLOAD_REPORT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const resetReport = createAction(HealthLabelReportActionTypes.RESET_REPORT);

export const clearNextError = createAction(HealthLabelReportActionTypes.CLEAR_NEXT_ERROR);
