import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { flatten, range } from 'lodash';

/** Constants */
import {
    ANIMAL_MILK_TAG_NAME,
    BIOMARKERS_TAG_NAME,
} from '@apps/leap/src/app/shared/modules/articles/constants/filters';

/** Helpers */
import { isFirstOccurrence, mapDecimalToPercentage } from '@leap-common/utilities/helpers';

/** Parsers */
import { EffectsParser } from '@apps/leap/src/app/shared/parsers/effects.parser';

/** Interfaces - Types - Enums */
import Range from '@leap-common/types/range.type';
import PaginatedArticlesRestApi from '../rest-api-interfaces/paginated-articles.rest.interface';
import PaginatedArticles from '../interfaces/paginated-articles.interface';
import ArticleInfoRestApi from '../rest-api-interfaces/article-info.rest.interface';
import ArticleInfo from '../interfaces/article-info.interface';
import ArticleRestApi from '../rest-api-interfaces/article.rest.interface';
import Article from '../interfaces/article.interface';
import ArticleSectionRestApi from '../rest-api-interfaces/article-section.rest.interface';
import ArticleSection from '../interfaces/article-section.interface';
import ArticleParagraph from '../interfaces/article-paragraph.interface';
import Bookmark from '@leap-store/core/src/lib/data/bookmarks/enums/bookmark.enum';
import FilterCounts from '@apps/leap/src/app/shared/modules/filters/interfaces/filter-counts.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import ArticleFilterCountsRestApi from '../rest-api-interfaces/filter-counts.rest.interface';
import ArticleFilterCounts from '../interfaces/filter-counts.interface';
import ArticlesContextRestApi from '../rest-api-interfaces/articles-context.rest.interface';
import EffectHighlightRestApi from '../rest-api-interfaces/effect-highlight.rest.interface';
import Effect from '../../ingredient-profiler/enums/effect.enum';

@Injectable()
export class ArticlesParser {
    constructor(private effectsParser: EffectsParser) {}

    /** Parses BE articleInfo to integrate them on the FE */
    parseArticlesInfo(articlesInfo: ArticleInfoRestApi[]): ArticleInfo[] {
        return articlesInfo.map((articleInfo: ArticleInfoRestApi) =>
            this.parseArticleInfo(articleInfo),
        );
    }

    parseArticleInfo(articleInfo: ArticleInfoRestApi): ArticleInfo {
        return {
            id: this.generateArticleInfoId(articleInfo),
            sourceId: articleInfo.sourceCui,
            targetId: articleInfo.targetCui,
            intermediateId: articleInfo.intermediateCui,
            dateRange: [articleInfo.oldestPublicationYear, articleInfo.newestPublicationYear],
            fullTextTotal: articleInfo.fullTextTotal,
            animalMilkTotal: articleInfo.tagCounts?.[ANIMAL_MILK_TAG_NAME],
            biomarkersTotal: articleInfo.insightClassesCount?.[BIOMARKERS_TAG_NAME],
            coOccurrencesCount: articleInfo.coOccurrencesCount,
            coOccurrencesTotal: articleInfo.coOccurrencesTotal,
            total: articleInfo.total,
        };
    }

    parsePaginatedArticles(paginatedArticles: PaginatedArticlesRestApi): PaginatedArticles {
        return {
            results: paginatedArticles.results ? this.parseArticles(paginatedArticles.results) : [],
            pageIndex: paginatedArticles.pageIndex,
            pageSize: paginatedArticles.pageSize,
            total: paginatedArticles.unfilteredTotal,
            filteredTotal: paginatedArticles.total,
            filterCounts: this.parseFilterCounts(
                paginatedArticles.counts,
                paginatedArticles.fullTextTotal,
            ),
            dateRange: [
                paginatedArticles.oldestPublicationYear,
                paginatedArticles.newestPublicationYear,
            ],
        };
    }

    parseArticles(articles: ArticleRestApi[]): Article[] {
        return articles.map((article: ArticleRestApi) => this.parseArticle(article));
    }

    parseArticle(article: ArticleRestApi): Article {
        return article
            ? {
                  type: Bookmark.article,
                  id: this.getUuid(),
                  abstract: article.abstract,
                  externalId: article.externalId,
                  publicationDate: article.publicationDate,
                  title: article.title,
                  url: article.url,
                  origin: article.source,
                  titleHighlights: article.titleSpans || [],
                  titleEntityHighlights: article.titleInsightSpans || [],
                  titleAdverseHighlights:
                      flatten(
                          this.parseEffectHighlights(
                              [article.effectClassificationSpans?.title],
                              [Effect.adverse],
                          ),
                      ) || [],
                  titleBeneficialHighlights:
                      flatten(
                          this.parseEffectHighlights(
                              [article.effectClassificationSpans?.title],
                              [Effect.beneficial],
                          ),
                      ) || [],
                  titleUnclassifiedHighlights:
                      flatten(
                          this.parseEffectHighlights(
                              [article.effectClassificationSpans?.title],
                              [Effect.unclassified],
                          ),
                      ) || [],
                  titleMixedEffectHighlights:
                      flatten(
                          this.parseEffectHighlights(
                              [article.effectClassificationSpans?.title],
                              [Effect.adverse, Effect.beneficial],
                          ),
                      ) || [],
                  titleSearchHighlights: article.titleSearchSpans || [],
                  abstractHighlights: article.abstractSpans || [],
                  abstractEntityHighlights: article.abstractInsightSpans || [],
                  abstractAdverseHighlights:
                      flatten(
                          this.parseEffectHighlights(
                              [article.effectClassificationSpans?.abstract],
                              [Effect.adverse],
                          ),
                      ) || [],
                  abstractBeneficialHighlights:
                      flatten(
                          this.parseEffectHighlights(
                              [article.effectClassificationSpans?.abstract],
                              [Effect.beneficial],
                          ),
                      ) || [],
                  abstractUnclassifiedHighlights:
                      flatten(
                          this.parseEffectHighlights(
                              [article.effectClassificationSpans?.abstract],
                              [Effect.unclassified],
                          ),
                      ) || [],
                  abstractMixedEffectHighlights:
                      flatten(
                          this.parseEffectHighlights(
                              [article.effectClassificationSpans?.abstract],
                              [Effect.adverse, Effect.beneficial],
                          ),
                      ) || [],
                  abstractSearchHighlights: article.abstractSearchSpans || [],
                  abstractReferenceHighlights: article.abstractReferenceSpans || [],
                  studyTypes: article.typesOfStudy || [],
                  journal: article.journal,
                  authors: article.authors,
                  fullText: article.fullText ? this.parseArticleFullText(article.fullText) : [],
                  isNew: article.isNew,
                  headings: article.headings,
                  relationshipTypes: this.parseRelationshipTypes(article.relations),
                  relevance: mapDecimalToPercentage(article.relevanceScore, 2),
                  relevantParagraphsCount: this.parseRelevantParagraphsCount(
                      article?.abstractReferenceSpans,
                      article?.fullText,
                  ),
                  doi: article.doi,
                  effects: this.effectsParser.parseEffects(article?.effectClasses),
              }
            : null;
    }

    parseRelevantParagraphsCount(abstract: Range[], fullText: ArticleSectionRestApi[]): number {
        // count non-empty tuples in the abstract
        const abstractCount: number =
            abstract?.filter(([start, end]: Range) => start + end > 0)?.length || 0;

        // count fullText paragraphs
        const paragraphsCount: number = flatten(
            flatten(
                fullText?.map(
                    ({ referenceSpans }: ArticleSectionRestApi) => referenceSpans || [],
                ) || [],
            ),
        ).filter(([start, end]: Range) => start + end > 0).length;

        return abstractCount + paragraphsCount;
    }

    parseArticleFullText(fullText: ArticleSectionRestApi[]): ArticleSection[] {
        return fullText.map((section: ArticleSectionRestApi) => this.parseArticleSection(section));
    }

    parseRelationshipTypes(relationshipTypes: Record<string, string>): string[] {
        return relationshipTypes ? Object.values(relationshipTypes).filter(isFirstOccurrence) : [];
    }

    parseArticleSection(section: ArticleSectionRestApi): ArticleSection {
        const mixedEffectHighlights: Range[][] = this.parseEffectHighlights(
            section.effectClassificationSpans,
            [Effect.adverse, Effect.beneficial],
        );
        const adverseHighlights: Range[][] = this.parseEffectHighlights(
            section.effectClassificationSpans,
            [Effect.adverse],
        );
        const beneficialHighlights: Range[][] = this.parseEffectHighlights(
            section.effectClassificationSpans,
            [Effect.beneficial],
        );
        const unclassifiedHighlights: Range[][] = this.parseEffectHighlights(
            section.effectClassificationSpans,
            [Effect.unclassified],
        );

        return {
            title: section.section,
            paragraphs: section.paragraphs
                ? this.parseArticleParagraphs(
                      section.paragraphs,
                      section.spans,
                      section.insightSpans,
                      section.referenceSpans,
                      beneficialHighlights,
                      adverseHighlights,
                      unclassifiedHighlights,
                      mixedEffectHighlights,
                  )
                : [],
        };
    }

    parseArticleParagraphs(
        texts: string[],
        highlights: Range[][],
        entityHighlights: Range[][],
        referenceHighlights: Range[][],
        beneficialHighlights: Range[][],
        adverseHighlights: Range[][],
        unclassifiedHighlights: Range[][],
        mixedEffectHighlights: Range[][],
    ): ArticleParagraph[] {
        return texts.map((text: string, index: number) => ({
            text,
            highlights: highlights?.[index] || [],
            entityHighlights: entityHighlights?.[index] || [],
            referenceHighlights: referenceHighlights?.[index] || [],
            beneficialHighlights: beneficialHighlights?.[index] || [],
            adverseHighlights: adverseHighlights?.[index] || [],
            unclassifiedHighlights: unclassifiedHighlights?.[index] || [],
            mixedEffectHighlights: mixedEffectHighlights?.[index] || [],
        }));
    }

    parseFilterCounts(
        counts: ArticleFilterCountsRestApi,
        fullTextTotal?: number,
    ): ArticleFilterCounts {
        return {
            studyTypes: counts?.type_of_study,
            journals: counts?.journal,
            origins: counts?.source,
            relationships: counts?.relations,
            effects: this.effectsParser.parseEffectsCounts(counts?.effect_classes),
            publicationDates: this.parseCountsPerPublicationDate(counts?.publication_date),
            fullTextTotal,
            animalMilkTotal: counts?.tags?.[ANIMAL_MILK_TAG_NAME],
        };
    }

    /**
     * Checks if there are years not existing in the BE response due to zero data
     * and adds a zero record for these years in order to appear correctly in the line chart
     */
    parseCountsPerPublicationDate(
        countsPerPublicationDate: Record<string, FilterCounts>,
    ): Record<string, FilterCounts> {
        if (!countsPerPublicationDate || !Object.keys(countsPerPublicationDate).length) {
            return {};
        }
        const years: number[] = Object.keys(countsPerPublicationDate).map(Number);
        // Create an array with the missing years included
        const allYears: number[] = range(Math.min(...years), Math.max(...years));

        const totalCountsPerPublicationDate: Record<string, FilterCounts> = allYears.reduce(
            (accumulator: Record<string, FilterCounts>, year: number) => {
                if (!countsPerPublicationDate[year]) {
                    accumulator[year] = { active: 0, total: 0 };
                }
                return accumulator;
            },
            { ...countsPerPublicationDate },
        );

        return totalCountsPerPublicationDate;
    }

    /**
     * Returns an array of arrays of ranges. E.g.: `P[ C[ G[], G[] ], C[ G[], G[] ] ]`
     *
     * * `P[]` Parent array corresponds to the type e.g. `effect`, `title`, `abstract`. If type is `title` or `abstract`, the length of the parent array is one (one child array).
     * * `C[]` Child arrays correspond to the `paragraphs`.
     * * `G[]` Grandchild arrays correspond to the span ranges within the specific paragraph.
     *
     * @param effects An array of effects that should exist in each child array
     */
    parseEffectHighlights(
        effectHighlights: EffectHighlightRestApi[][],
        effects: Effect[],
    ): Range[][] {
        return (
            effectHighlights?.map((paragraphs: EffectHighlightRestApi[]) => {
                const child: Range[] = [];

                paragraphs?.forEach((paragraph: EffectHighlightRestApi) => {
                    const paragraphEffects: Effect[] = this.effectsParser.parseEffects(
                        paragraph?.effects,
                    );

                    const areProvidedEffectsFoundInParagraphEffects: boolean = effects.every(
                        (effect: Effect) => paragraphEffects.includes(effect),
                    );

                    if (areProvidedEffectsFoundInParagraphEffects) {
                        const grandchild: Range = paragraph?.span;
                        child.push(grandchild);
                    }
                });

                return child;
            }) || []
        );
    }

    serializeArticlesContext(
        filters: ExecutionFilters,
        searchQuery: string,
        query: string,
    ): ArticlesContextRestApi {
        return {
            filters: { ...filters, phrase: searchQuery },
            query,
        };
    }

    getUuid(): string {
        return uuid();
    }

    generateArticleInfoId(articleInfo: ArticleInfoRestApi): string {
        return articleInfo.intermediateCui
            ? `${articleInfo.sourceCui}_${articleInfo.intermediateCui}_${articleInfo.targetCui}`
            : `${articleInfo.sourceCui}_${articleInfo.targetCui}`;
    }
}
