/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as healthLabelReportActions from './health-label-report.actions';
import { HealthLabelReportState } from './health-label-report-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Overview from './interfaces/overview.interface';
import Compound from './interfaces/compound.interface';
import Target from './interfaces/target.interface';
import TermSentences from '../compound-report/interfaces/term-sentences.interface';

export const initialState: HealthLabelReportState = {
    errors: [],
    overview: null,
    overviewLoading: false,
    overviewLoaded: false,
    topCompounds: [],
    topCompoundsLoading: false,
    topCompoundsLoaded: false,
    topTargets: [],
    topTargetsLoading: false,
    topTargetsLoaded: false,
    sentences: [],
    sentencesLoading: false,
    sentencesLoaded: false,
    blob: null,
};

const profilerReducer: ActionReducer<HealthLabelReportState, Action> = createReducer(
    initialState,
    on(healthLabelReportActions.getOverviewRequest, (state: HealthLabelReportState) => ({
        ...state,
        overviewLoading: true,
        overviewLoaded: false,
    })),
    on(
        healthLabelReportActions.getOverviewSuccess,
        (state: HealthLabelReportState, { overview }: { overview: Overview }) => ({
            ...state,
            overview,
            overviewLoading: false,
            overviewLoaded: true,
        }),
    ),
    on(
        healthLabelReportActions.getOverviewFailure,
        (
            state: HealthLabelReportState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            overviewLoading: false,
            overviewLoaded: false,
        }),
    ),
    on(healthLabelReportActions.getTopCompoundsRequest, (state: HealthLabelReportState) => ({
        ...state,
        topCompoundsLoading: true,
        topCompoundsLoaded: false,
    })),
    on(
        healthLabelReportActions.getTopCompoundsSuccess,
        (state: HealthLabelReportState, { topCompounds }: { topCompounds: Compound[] }) => ({
            ...state,
            topCompounds,
            topCompoundsLoading: false,
            topCompoundsLoaded: true,
        }),
    ),
    on(
        healthLabelReportActions.getTopCompoundsFailure,
        (
            state: HealthLabelReportState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            topCompoundsLoading: false,
            topCompoundsLoaded: false,
        }),
    ),
    on(healthLabelReportActions.getTopTargetsRequest, (state: HealthLabelReportState) => ({
        ...state,
        topTargetsLoading: true,
        topTargetsLoaded: false,
    })),
    on(
        healthLabelReportActions.getTopTargetsSuccess,
        (state: HealthLabelReportState, { topTargets }: { topTargets: Target[] }) => ({
            ...state,
            topTargets,
            topTargetsLoading: false,
            topTargetsLoaded: true,
        }),
    ),
    on(
        healthLabelReportActions.getTopTargetsFailure,
        (
            state: HealthLabelReportState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            topTargetsLoading: false,
            topTargetsLoaded: false,
        }),
    ),
    on(healthLabelReportActions.getSentencesRequest, (state: HealthLabelReportState) => ({
        ...state,
        sentencesLoading: true,
        sentencesLoaded: false,
    })),
    on(
        healthLabelReportActions.getSentencesSuccess,
        (state: HealthLabelReportState, { sentences }: { sentences: TermSentences[] }) => ({
            ...state,
            sentences,
            sentencesLoading: false,
            sentencesLoaded: true,
        }),
    ),
    on(
        healthLabelReportActions.getSentencesFailure,
        (
            state: HealthLabelReportState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            sentencesLoading: false,
            sentencesLoaded: false,
        }),
    ),
    on(
        healthLabelReportActions.downloadReportRequest,
        (state: HealthLabelReportState): HealthLabelReportState => ({
            ...state,
            blob: null,
        }),
    ),
    on(
        healthLabelReportActions.downloadReportSuccess,
        (state: HealthLabelReportState, { blob }: { blob: Blob }): HealthLabelReportState => ({
            ...state,
            blob,
        }),
    ),
    on(
        healthLabelReportActions.downloadReportFailure,
        (
            state: HealthLabelReportState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ): HealthLabelReportState => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            blob: null,
        }),
    ),
    on(healthLabelReportActions.resetReport, () => initialState),
    on(healthLabelReportActions.clearNextError, (state: HealthLabelReportState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: HealthLabelReportState | undefined,
    action: Action,
): HealthLabelReportState => profilerReducer(state, action);

// selectors
export const getErrors: (state: HealthLabelReportState) => ErrorResponse[] = (
    state: HealthLabelReportState,
) => state.errors;
export const getOverview: (state: HealthLabelReportState) => Overview = (
    state: HealthLabelReportState,
) => state.overview;
export const getOverviewLoading: (state: HealthLabelReportState) => boolean = (
    state: HealthLabelReportState,
) => state.overviewLoading;
export const getOverviewLoaded: (state: HealthLabelReportState) => boolean = (
    state: HealthLabelReportState,
) => state.overviewLoaded;
export const getTopCompounds: (state: HealthLabelReportState) => Compound[] = (
    state: HealthLabelReportState,
) => state.topCompounds;
export const getTopCompoundsLoading: (state: HealthLabelReportState) => boolean = (
    state: HealthLabelReportState,
) => state.topCompoundsLoading;
export const getTopCompoundsLoaded: (state: HealthLabelReportState) => boolean = (
    state: HealthLabelReportState,
) => state.topCompoundsLoaded;
export const getTopTargets: (state: HealthLabelReportState) => Target[] = (
    state: HealthLabelReportState,
) => state.topTargets;
export const getTopTargetsLoading: (state: HealthLabelReportState) => boolean = (
    state: HealthLabelReportState,
) => state.topTargetsLoading;
export const getTopTargetsLoaded: (state: HealthLabelReportState) => boolean = (
    state: HealthLabelReportState,
) => state.topTargetsLoaded;
export const getSentences: (state: HealthLabelReportState) => TermSentences[] = (
    state: HealthLabelReportState,
) => state.sentences;
export const getSentencesLoading: (state: HealthLabelReportState) => boolean = (
    state: HealthLabelReportState,
) => state.sentencesLoading;
export const getSentencesLoaded: (state: HealthLabelReportState) => boolean = (
    state: HealthLabelReportState,
) => state.sentencesLoaded;
export const getBlob: (state: HealthLabelReportState) => Blob = (state: HealthLabelReportState) =>
    state.blob;
