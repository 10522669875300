/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { CompoundReportActionTypes } from './compound-report-action-types.enum';
import Overview from './interfaces/overview.interface';
import Effect from '../ingredient-profiler/enums/effect.enum';
import TermsSentences from './interfaces/terms-sentences.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

export const getOverviewRequest = createAction(
    CompoundReportActionTypes.GET_OVERVIEW_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        filters: ExecutionFilters;
    }>(),
);

export const getOverviewSuccess = createAction(
    CompoundReportActionTypes.GET_OVERVIEW_SUCCESS,
    props<{ overview: Overview; total: number }>(),
);

export const getOverviewFailure = createAction(
    CompoundReportActionTypes.GET_OVERVIEW_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getEffectSentencesRequest = createAction(
    CompoundReportActionTypes.GET_EFFECT_SENTENCES_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        effect: Effect;
        filters: ExecutionFilters;
    }>(),
);

export const getEffectSentencesSuccess = createAction(
    CompoundReportActionTypes.GET_EFFECT_SENTENCES_SUCCESS,
    props<{ effectSentences: TermsSentences }>(),
);

export const getEffectSentencesFailure = createAction(
    CompoundReportActionTypes.GET_EFFECT_SENTENCES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelsRequest = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABELS_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        filters: ExecutionFilters;
    }>(),
);

export const getHealthLabelsSuccess = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABELS_SUCCESS,
    props<{ healthLabels: HealthLabel[] }>(),
);

export const getHealthLabelsFailure = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABELS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelSentencesRequest = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABEL_SENTENCES_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        healthLabel: string;
        effect: Effect;
        filters: ExecutionFilters;
    }>(),
);

export const getHealthLabelSentencesSuccess = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABEL_SENTENCES_SUCCESS,
    props<{ sentences: TermsSentences }>(),
);

export const getHealthLabelSentencesFailure = createAction(
    CompoundReportActionTypes.GET_HEALTH_LABEL_SENTENCES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getStudyTypesRequest = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPES_REQUEST,
    props<{ compoundId: string; ingredientName: string; filters: ExecutionFilters }>(),
);

export const getStudyTypesSuccess = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPES_SUCCESS,
    props<{ studyTypes: StudyTypes }>(),
);

export const getStudyTypesFailure = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getStudyTypeRequest = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPE_REQUEST,
    props<{
        compoundId: string;
        ingredientName: string;
        name: string;
        filters: ExecutionFilters;
    }>(),
);

export const getStudyTypeSuccess = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPE_SUCCESS,
    props<{ studyType: StudyType }>(),
);

export const getStudyTypeFailure = createAction(
    CompoundReportActionTypes.GET_STUDY_TYPE_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadRequest = createAction(
    CompoundReportActionTypes.DOWNLOAD_REQUEST,
    props<{ compoundId: string; ingredientName: string }>(),
);

export const downloadSuccess = createAction(
    CompoundReportActionTypes.DOWNLOAD_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadFailure = createAction(
    CompoundReportActionTypes.DOWNLOAD_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const resetReport = createAction(CompoundReportActionTypes.RESET_REPORT);

export const clearNextError = createAction(CompoundReportActionTypes.CLEAR_NEXT_ERROR);
