export enum HealthLabelReportActionTypes {
    GET_OVERVIEW_REQUEST = '[HEALTH LABEL REPORT][OVERVIEW] Get Overview Request',
    GET_OVERVIEW_SUCCESS = '[HEALTH LABEL REPORT][OVERVIEW] Get Overview Success',
    GET_OVERVIEW_FAILURE = '[HEALTH LABEL REPORT][OVERVIEW] Get Overview Failure',
    GET_TOP_COMPOUNDS_REQUEST = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Top Compounds Request',
    GET_TOP_COMPOUNDS_SUCCESS = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Top Compounds Success',
    GET_TOP_COMPOUNDS_FAILURE = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Top Compounds Failure',
    GET_TOP_TARGETS_REQUEST = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Top Targets Request',
    GET_TOP_TARGETS_SUCCESS = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Top Targets Success',
    GET_TOP_TARGETS_FAILURE = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Top Targets Failure',
    GET_SENTENCES_REQUEST = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Sentences Request',
    GET_SENTENCES_SUCCESS = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Sentences Success',
    GET_SENTENCES_FAILURE = '[HEALTH LABEL REPORT][HEALTH LABELS] Get Sentences Failure',
    DOWNLOAD_REPORT_REQUEST = '[HEALTH LABEL REPORT][DOWNLOAD] Download Report Request',
    DOWNLOAD_REPORT_SUCCESS = '[HEALTH LABEL REPORT][DOWNLOAD] Download Report Success',
    DOWNLOAD_REPORT_FAILURE = '[HEALTH LABEL REPORT][DOWNLOAD] Download Report Failure',
    RESET_REPORT = '[HEALTH LABEL REPORT] Reset Report',
    CLEAR_NEXT_ERROR = '[HEALTH LABEL REPORT] Clear Next Error',
}
