/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromHealthLabelReport from './health-label-report.reducer';

export const getHealthLabelReportState = createSelector(
    getDataState,
    (state: DataState) => state.healthLabelReport,
);
export const getErrors = createSelector(getHealthLabelReportState, fromHealthLabelReport.getErrors);
export const getOverview = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getOverview,
);
export const getOverviewLoading = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getOverviewLoading,
);
export const getOverviewLoaded = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getOverviewLoaded,
);
export const getTopCompounds = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getTopCompounds,
);
export const getTopCompoundsLoading = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getTopCompoundsLoading,
);
export const getTopCompoundsLoaded = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getTopCompoundsLoaded,
);
export const getTopTargets = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getTopTargets,
);
export const getTopTargetsLoading = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getTopTargetsLoading,
);
export const getTopTargetsLoaded = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getTopTargetsLoaded,
);
export const getSentences = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getSentences,
);
export const getSentencesLoading = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getSentencesLoading,
);
export const getSentencesLoaded = createSelector(
    getHealthLabelReportState,
    fromHealthLabelReport.getSentencesLoaded,
);
export const getBlob = createSelector(getHealthLabelReportState, fromHealthLabelReport.getBlob);
