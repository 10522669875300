/** third-party imports */
import { Injectable } from '@angular/core';

/** Constants */
import { SERIALIZED_COMPOUND_TERM_TYPE } from '../constants/health-label-report';

/** Parsers */
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { MetadataParser } from '../../metadata/parsers/metadata.parser';

/** Interfaces - Enums */
import Overview from '../interfaces/overview.interface';
import HealthLabelInfoRestApi from '../rest-api-interfaces/health-label-info.rest.interface';
import Compound from '../interfaces/compound.interface';
import CompoundRestApi from '../rest-api-interfaces/compound.rest.interface';
import TargetRestApi from '../rest-api-interfaces/target.rest.interface';
import Effect from '../../ingredient-profiler/enums/effect.enum';
import RelationshipGroup from '../../ingredient-profiler/enums/relationship-group.enum';
import Target from '../interfaces/target.interface';
import ProfilerEntity from '../../ingredient-profiler/enums/entity.enum';

@Injectable()
export class HealthLabelReportParser {
    constructor(private insightParser: InsightParser, private metadataParser: MetadataParser) {}

    parseOverview(healthLabelsInfo: HealthLabelInfoRestApi[]): Overview {
        return healthLabelsInfo.reduce(
            (accumulator: Overview, healthLabelInfo: HealthLabelInfoRestApi) => {
                accumulator.statistics.push({
                    healthLabel: healthLabelInfo.healthArea,
                    statistics: {
                        [RelationshipGroup.prevents]: healthLabelInfo[RelationshipGroup.prevents],
                        [RelationshipGroup.causes]: healthLabelInfo[RelationshipGroup.causes],
                        [Effect.beneficial]: healthLabelInfo[Effect.beneficial],
                        [Effect.adverse]: healthLabelInfo[Effect.adverse],
                    },
                });

                return accumulator;
            },
            { statistics: [] },
        );
    }

    parseCompounds(topCompounds: CompoundRestApi[]): Compound[] {
        return topCompounds.map((topCompound: CompoundRestApi, index: number) =>
            this.parseCompound(topCompound, index),
        );
    }

    parseCompound(topCompound: CompoundRestApi, index: number): Compound {
        return {
            id: topCompound.associatedTermUid,
            name: topCompound.associatedTermName,
            categories: this.insightParser.parseCategories(topCompound.associatedTermCategories),
            rankingIndex: index + 1,
            cowMilkConcentration: topCompound.cowMilkConcentration,
            prevalence: this.metadataParser.parsePrevalence(topCompound.prevalence),
            healthLabels: topCompound.healthAreas,
            associationsCount: topCompound.noOfAssociations,
            isReportEnabled: topCompound.reportAllowed,
        };
    }

    parseTargets(topTargets: TargetRestApi[]): Target[] {
        return topTargets.map((topTarget: TargetRestApi, index: number) =>
            this.parseTarget(topTarget, index),
        );
    }

    parseTarget(topTarget: TargetRestApi, index: number): Target {
        return {
            id: topTarget.associatedTermUid,
            name: topTarget.associatedTermName,
            categories: topTarget.associatedTermCategories,
            rankingIndex: index + 1,
            healthLabels: topTarget.healthAreas,
            associationsCount: topTarget.noOfAssociations,
        };
    }

    serializeTermType(termType: ProfilerEntity): string {
        return termType === ProfilerEntity.compound
            ? SERIALIZED_COMPOUND_TERM_TYPE
            : termType === ProfilerEntity.target
            ? ProfilerEntity.target
            : null;
    }
}
