/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { HealthLabelReportService } from './services/health-label-report.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './health-label-report.actions';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import Overview from './interfaces/overview.interface';
import Compound from './interfaces/compound.interface';
import Target from './interfaces/target.interface';
import TopTermsPayload from './interfaces/top-terms-payload.interface';
import Effect from '../ingredient-profiler/enums/effect.enum';
import TermSentences from '../compound-report/interfaces/term-sentences.interface';
import ProfilerEntity from '../ingredient-profiler/enums/entity.enum';

@Injectable()
export class HealthLabelReportEffects {
    constructor(
        private actions$: Actions,
        private healthLabelReportService: HealthLabelReportService,
    ) {}

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(
                ({
                    ingredientName,
                    countBy,
                    filters,
                    preferences,
                }: {
                    ingredientName: string;
                    countBy: string;
                    filters: ExecutionFilters;
                    preferences: UserPreferences;
                }) =>
                    this.healthLabelReportService
                        .getOverview(undefined, ingredientName, countBy, filters, preferences)
                        .pipe(
                            map((overview: Overview) => actions.getOverviewSuccess({ overview })),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getOverviewFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getTopCompounds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTopCompoundsRequest),
            mergeMap((payload: TopTermsPayload) =>
                this.healthLabelReportService.getTopCompounds(payload).pipe(
                    map((topCompounds: Compound[]) =>
                        actions.getTopCompoundsSuccess({
                            topCompounds,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getTopCompoundsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getTopTargets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTopTargetsRequest),
            mergeMap((payload: TopTermsPayload) =>
                this.healthLabelReportService.getTopTargets(payload).pipe(
                    map((topTargets: Target[]) =>
                        actions.getTopTargetsSuccess({
                            topTargets,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getTopTargetsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getSentences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSentencesRequest),
            mergeMap(
                ({
                    ingredientName,
                    id,
                    termType,
                    healthLabel,
                    effect,
                    filters,
                    preferences,
                }: {
                    ingredientName: string;
                    id: string;
                    termType: ProfilerEntity;
                    healthLabel: string;
                    effect: Effect;
                    filters: ExecutionFilters;
                    preferences: UserPreferences;
                }) =>
                    this.healthLabelReportService
                        .getSentences(
                            ingredientName,
                            id,
                            termType,
                            healthLabel,
                            effect,
                            filters,
                            preferences,
                        )
                        .pipe(
                            map((sentences: TermSentences[]) =>
                                actions.getSentencesSuccess({ sentences }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getSentencesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    downloadReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadReportRequest),
            switchMap(
                ({
                    ingredientName,
                    healthLabel,
                    effect,
                    termId,
                    termType,
                    filters,
                    countBy,
                    preferences,
                }: {
                    ingredientName: string;
                    healthLabel: string;
                    effect: Effect;
                    termId: string;
                    termType: ProfilerEntity;
                    countBy: string;
                    filters: ExecutionFilters;
                    preferences: UserPreferences;
                }) =>
                    this.healthLabelReportService
                        .downloadReport(
                            ingredientName,
                            healthLabel,
                            effect,
                            termId,
                            termType,
                            countBy,
                            filters,
                            preferences,
                        )
                        .pipe(
                            map((blob: Blob) => actions.downloadReportSuccess({ blob })),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.downloadReportFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );
}
