/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { HealthLabelReportState } from './health-label-report-state.interface';
import {
    getOverviewRequest,
    getTopCompoundsRequest,
    getTopTargetsRequest,
    getSentencesRequest,
    downloadReportRequest,
    resetReport,
    clearNextError,
} from './health-label-report.actions';
import {
    getErrors,
    getOverview,
    getOverviewLoading,
    getOverviewLoaded,
    getTopCompounds,
    getTopCompoundsLoading,
    getTopCompoundsLoaded,
    getTopTargets,
    getTopTargetsLoading,
    getTopTargetsLoaded,
    getSentences,
    getSentencesLoading,
    getSentencesLoaded,
    getBlob,
} from './health-label-report.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import Overview from './interfaces/overview.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import Compound from './interfaces/compound.interface';
import Target from './interfaces/target.interface';
import TopTermsPayload from './interfaces/top-terms-payload.interface';
import Effect from '../ingredient-profiler/enums/effect.enum';
import TermSentences from '../compound-report/interfaces/term-sentences.interface';
import ProfilerEntity from '../ingredient-profiler/enums/entity.enum';
import DownloadReportPayload from './interfaces/download-report-payload.interface';

@Injectable()
export class HealthLabelReportFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    overview$: Observable<Overview> = this.store.pipe(select(getOverview));
    overviewLoading$: Observable<boolean> = this.store.pipe(select(getOverviewLoading));
    overviewLoaded$: Observable<boolean> = this.store.pipe(select(getOverviewLoaded));
    topCompounds$: Observable<Compound[]> = this.store.pipe(select(getTopCompounds));
    topCompoundsLoading$: Observable<boolean> = this.store.pipe(select(getTopCompoundsLoading));
    topCompoundsLoaded$: Observable<boolean> = this.store.pipe(select(getTopCompoundsLoaded));
    topTargets$: Observable<Target[]> = this.store.pipe(select(getTopTargets));
    topTargetsLoading$: Observable<boolean> = this.store.pipe(select(getTopTargetsLoading));
    topTargetsLoaded$: Observable<boolean> = this.store.pipe(select(getTopTargetsLoaded));
    sentences$: Observable<TermSentences[]> = this.store.pipe(select(getSentences));
    sentencesLoading$: Observable<boolean> = this.store.pipe(select(getSentencesLoading));
    sentencesLoaded$: Observable<boolean> = this.store.pipe(select(getSentencesLoaded));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));

    constructor(private store: Store<HealthLabelReportState>) {}

    getOverview({
        ingredientName,
        countBy,
        filters,
        preferences,
    }: {
        ingredientName?: string;
        countBy: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(getOverviewRequest({ ingredientName, countBy, filters, preferences }));
    }

    getTopCompounds(payload: TopTermsPayload): void {
        this.store.dispatch(getTopCompoundsRequest(payload));
    }

    getTopTargets(payload: TopTermsPayload): void {
        this.store.dispatch(getTopTargetsRequest(payload));
    }

    getSentences(
        ingredientName: string,
        id: string,
        termType: ProfilerEntity,
        healthLabel: string,
        effect: Effect,
        filters: ExecutionFilters,
        preferences: UserPreferences,
    ): void {
        this.store.dispatch(
            getSentencesRequest({
                ingredientName,
                id,
                termType,
                healthLabel,
                effect,
                filters,
                preferences,
            }),
        );
    }

    downloadReport({
        ingredientName,
        healthLabel,
        effect,
        termId,
        termType,
        countBy,
        filters,
        preferences,
    }: DownloadReportPayload): void {
        this.store.dispatch(
            downloadReportRequest({
                ingredientName,
                healthLabel,
                effect,
                termId,
                termType,
                countBy,
                filters,
                preferences,
            }),
        );
    }

    resetReport(): void {
        this.store.dispatch(resetReport());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
