import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { IngredientProfilerEffects } from './ingredient-profiler.effects';
import { IngredientProfilerFacade } from './ingredient-profiler.facade';
import { ProfilerReportParser } from './parsers/profiler-report.parser';
import { IngredientProfilerParser } from './parsers/ingredient-profiler.parser';
import { IngredientProfilerService } from './services/ingredient-profiler.service';
import { ProfilerReportService } from './services/profiler-report.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([IngredientProfilerEffects]),
    ],
    declarations: [],
    providers: [
        ProfilerReportParser,
        IngredientProfilerParser,
        ProfilerReportService,
        IngredientProfilerService,
        IngredientProfilerFacade,
    ],
})
export class IngredientProfilerStoreModule {}
