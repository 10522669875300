/** third-party libraries */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { IngredientProfilerParser } from '../parsers/ingredient-profiler.parser';
import { SuggestionsParser } from '@apps/leap/src/app/shared/parsers/suggestions.parser';
import { CategoriesParser } from '../../discovery/categories/parsers/categories.parser';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';
import { HttpParamEncoder } from '@leap-common/services/http-param-encoder.service';
import SuggestionRestApi from '@apps/leap/src/app/shared/rest-api-interfaces/suggestion.rest.interface';
import Suggestion from '@apps/leap/src/app/shared/interfaces/suggestion.interface';
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import PaginatedCompoundsRestApi from '../rest-api-interfaces/paginated-compounds.rest.interface';
import PaginatedCompounds from '../interfaces/paginated-compounds.interface';
import PaginatedInsightsRestApi from '../rest-api-interfaces/paginated-insights.rest.interface';
import PaginatedInsights from '../interfaces/paginated-insights.interface';
import Insight from '../interfaces/insight.interface';
import TargetRestApi from '../rest-api-interfaces/target.rest.interface';
import Target from '../interfaces/target.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import CustomIngredientRestApi from '../rest-api-interfaces/custom-ingredient.rest.interface';
import CustomIngredient from '../interfaces/custom-ingredient.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';

@Injectable()
export class IngredientProfilerService {
    constructor(
        private http: HttpClient,
        private ingredientProfilerParser: IngredientProfilerParser,
        private suggestionsParser: SuggestionsParser,
        private categoriesParser: CategoriesParser,
        private insightsService: InsightsService,
    ) {}

    getSuggestions(query: string, limit: number): Observable<Suggestion[]> {
        const noOfSuggestions: string = limit.toString();

        let params: HttpParams = new HttpParams({ encoder: new HttpParamEncoder() });
        params = params.append('query', query);
        params = params.append('noOfSuggestions', noOfSuggestions);

        return this.http
            .get(`${environment.profilerAutocompleteServerUrl}/autocomplete`, { params })
            .pipe(
                map((suggestions: ResultsRestApi<SuggestionRestApi>) =>
                    this.suggestionsParser.parseSuggestions(suggestions.results, query),
                ),
            );
    }

    getCustomIngredient(file: File): Observable<CustomIngredient> {
        const formData: FormData = new FormData();
        formData.append('components', file);

        return this.http
            .post(`${environment.profilerServerUrl}/ingredient`, formData)
            .pipe(
                map((customIngredient: CustomIngredientRestApi) =>
                    this.ingredientProfilerParser.parseCustomIngredient(customIngredient),
                ),
            );
    }

    getCompounds(
        ingredientId: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        sortingOptions: SortingOptions, // this parameter is required because the initial sorting is different in CMP and in IP.
        preferences?: UserPreferences,
    ): Observable<PaginatedCompounds> {
        return this.http
            .post(`${environment.profilerServerUrl}/components`, {
                ingredientUid: ingredientId,
                filters,
                pageSize,
                pageIndex,
                sortingBy: sortingOptions.field,
                sortingOrder: sortingOptions.order,
                preferences,
            })
            .pipe(
                map((paginatedResults: PaginatedCompoundsRestApi) =>
                    this.ingredientProfilerParser.parsePaginatedCompounds(
                        paginatedResults,
                        ingredientId,
                    ),
                ),
            );
    }

    getInsights(
        ingredientId: string,
        compoundId: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        preferences: UserPreferences,
        sortingOptions?: SortingOptions,
    ): Observable<PaginatedInsights> {
        return this.http
            .post(`${environment.profilerServerUrl}/insights`, {
                ingredientUid: ingredientId,
                sourceUid: compoundId,
                filters,
                pageSize,
                pageIndex,
                preferences,
                ...(Boolean(sortingOptions) && {
                    sortingBy: sortingOptions.field,
                    sortingOrder: sortingOptions.order,
                }),
            })
            .pipe(
                map((paginatedResults: PaginatedInsightsRestApi) =>
                    this.ingredientProfilerParser.parsePaginatedInsights(paginatedResults),
                ),
            );
    }

    getIngredientInsights(
        ingredientId: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        preferences: UserPreferences,
    ): Observable<PaginatedResults<Target>> {
        return this.http
            .post(`${environment.profilerServerUrl}/targets`, {
                ingredientUid: ingredientId,
                filters,
                pageSize,
                pageIndex,
                preferences,
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<TargetRestApi>) =>
                    this.ingredientProfilerParser.parsePaginatedTargets(paginatedResults),
                ),
            );
    }

    getTargetInsights(
        ingredientId: string,
        targetId: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        preferences: UserPreferences,
    ): Observable<PaginatedInsights> {
        return this.http
            .post(`${environment.profilerServerUrl}/insights`, {
                ingredientUid: ingredientId,
                targetUid: targetId,
                filters,
                pageSize,
                pageIndex,
                preferences,
            })
            .pipe(
                map((paginatedResults: PaginatedInsightsRestApi) => {
                    const parsedPaginatedInsights: PaginatedInsights =
                        this.ingredientProfilerParser.parsePaginatedInsights(paginatedResults);

                    const reversedInsights: Insight[] = parsedPaginatedInsights.results.map(
                        (insight: Insight) =>
                            this.insightsService.reverseInsightProperties(insight),
                    );

                    return {
                        ...parsedPaginatedInsights,
                        results: reversedInsights,
                    };
                }),
            );
    }

    downloadInsights(
        ingredientId: string,
        filters: ExecutionFilters,
        sortingOptions: SortingOptions, // this parameter is required because the initial sorting is different in CMP and in IP.
        preferences?: UserPreferences,
    ): Observable<Blob> {
        return this.http.post(
            `${environment.profilerServerUrl}/insights/download`,
            {
                ingredientUid: ingredientId,
                filters,
                sortingBy: sortingOptions.field,
                sortingOrder: sortingOptions.order,
                preferences,
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }

    getCategories(): Observable<Record<string, string[]>> {
        return this.http
            .get(`${environment.profilerServerUrl}/categories?names`)
            .pipe(
                map((categories: Record<string, string[]>) =>
                    this.categoriesParser.parseCategories(categories),
                ),
            );
    }

    getSearchSuggestions(
        ingredientId: string,
        query: string,
        activeSearch: ProfilerSearch,
    ): Observable<string[]> {
        const searchPath: string =
            activeSearch === ProfilerSearch.compounds ||
            activeSearch === ProfilerSearch.targetInsights
                ? 'components'
                : ProfilerSearch.insights
                ? 'targets'
                : null;

        return this.http
            .post(`${environment.profilerServerUrl}/${searchPath}/search`, {
                ingredientUid: ingredientId,
                searchString: query,
            })
            .pipe(map(({ results }: ResultsRestApi<string>) => results));
    }
}
